/* =============================================================================
	JS - security.js
   ========================================================================== */
window.Security = function(){

    var userId;

    return { init: init, loadingOn: loadingOn, loadingOff: loadingOff  };

    /**
     * Initialize our class
     */
    function init(){
        $('.resetPassword').click(function(e){
            let $user = $(this).data('user');
            e.preventDefault();

            Security.loadingOn()
            $.post('/ajaxadmin/customer/reset-password', {user: $user})
                .done(function (data) {
                    $.growl.notice({
                        title: "Success",
                        message: "An email was sent to the customer with the link to reset the password."
                    });
                })
                .fail(function (data){
                    $.growl.error({
                        title: "Error",
                        message: "An error occurred, the password could not be reset correctly, contact the dev team.",
                        duration: 5200
                    });
                })
                .always(function() {
                    Security.loadingOff()
                });
        });
    }

    /**
     * 
     */
    function loadingOn() {
        $('#mainarea').append('<div id="customer_overlay" class="footable_overlay"><img src="/images/loading.svg" class="tableLoading" /></div>');
    }

    /**
     * 
     */
    function loadingOff() {
        $("#customer_overlay").remove();
    }
}();